/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { fetchSeriesStats } from '../../../../api/dataService';
import './seriesDescription.css';

const SeriesDescription = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const seriesStats = await fetchSeriesStats('IND-VS-ENG-T20I-JAN-FEB-2025');
        if (isMounted.current) {
          setData(seriesStats);
          setErrorMessage(null);
        }
      } catch (error) {
        if (isMounted.current) {
          setErrorMessage('We have run into an issue, please try again later.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading Series Stats!</p>
        <div className="spinner" />
      </div>
    );
  }

  if (errorMessage) {
    return <div className="error-message">{errorMessage}</div>;
  }

  const seriesStats = [
    {
      statName: 'Most Runs',
      stats: data.seriesStats.MostRuns.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Runs} Runs`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Most Wickets',
      stats: data.seriesStats.MostWickets.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `${stat.Wickets} Wickets`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Strike Rate',
      stats: data.seriesStats.BestStrikeRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `SR: ${stat.StrikeRate}`,
        isTop: i === 0,
      })),
    },
    {
      statName: 'Best Economy Rate',
      stats: data.seriesStats.BestEconomyRate.slice(0, 3).map((stat, i) => ({
        playerName: stat.Name,
        photo: stat.Avatar,
        statValue: `ER: ${stat.EconomyRate}`,
        isTop: i === 0,
      })),
    },
  ];

  return (
    <div className="statsNguts__seriesDescription section__padding" id="seriesDescription">
      <div className="statsNguts__seriesDescription_heading">
        <h1 className="gradient__text">England tour of India, January-February 2025</h1>
      </div>
      <div className="statsNguts__seriesDescription_text">
        <p>
          The cricket rivalry between India and England in T20 Internationals (T20Is) is one of the most exciting and competitive matchups in the sport. Known for their contrasting playing styles, India, with its flair and spin dominance, and England, with its aggressive power-hitting and pace attack, have delivered numerous thrilling encounters in the shortest format.
        </p>
        <p>
          Over the years, iconic performances such as Yuvraj Singh&apos;s six sixes in an over against Stuart Broad in 2007 and England&apos;s comeback series win in 2022 have added to the intensity of this rivalry. Both teams continue to vie for supremacy in global tournaments, making their clashes must-watch events for cricket fans worldwide.
        </p>
      </div>
      <div className="statsNguts__seriesResult">
        <p className="statsNguts__seriesResult_text">India leading 5-match series 1-0</p>
      </div>
      <div className="statsNguts__seriesDescription_stats">
        {seriesStats.map((statCategory, index) => (
          <div key={index} className="statsNguts__seriesDescription_statItem">
            <h3>{statCategory.statName}</h3>
            <div className="statsNguts__seriesDescription_statList">
              {statCategory.stats.map((stat, i) => (
                <div
                  key={i}
                  className={`statsNguts__seriesDescription_statDetail ${stat.isTop ? 'highlight' : ''}`}
                >
                  <img
                    src={require(`../../../../assets/image/players/${stat.photo}`)}
                    alt={stat.playerName}
                    className="statsNguts__seriesDescription_photo"
                  />
                  <p className="statsNguts__seriesDescription_player">{stat.playerName}</p>
                  <p className="statsNguts__seriesDescription_value">{stat.statValue}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeriesDescription;
