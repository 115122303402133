import React from 'react';
import { useParams } from 'react-router-dom';

import IPL2024MatchStats from './IPL-2024/matchStats/MatchStats';
import MatchStats from './common/matchStats/MatchStats';
import WPL2025MatchStats from './WPL-2025/matchStats/MatchStats';

const SeriesMatchStats = () => {
  const { seriesId, matchNumber, matchId } = useParams();

  const componentsMap = {
    'IPL-2024': [
      <IPL2024MatchStats key="matchStatsIPL2024" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'ICC-Mens-T20-WC-2024': [
      <MatchStats key="matchStatsICCMensT20WC2024" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'SA-VS-IND-T20I-NOV-2024': [
      <MatchStats key="matchStatsSAVSINDT20INOV2024" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'SL-VS-NZ-T20I-NOV-2024': [
      <MatchStats key="matchStatsSLVSNZT20INOV2024" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'WI-VS-ENG-T20I-NOV-2024': [
      <MatchStats key="matchStatsWIVSENGT20INOV2024" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'IND-VS-ENG-T20I-JAN-FEB-2025': [
      <MatchStats key="matchStatsINDVSENGT20IJANFEB2025" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
    'WPL-2025': [
      <WPL2025MatchStats key="matchStatsWPL2025" seriesId={seriesId} matchNumber={matchNumber} matchId={matchId} />,
    ],
  };

  const componentsToRender = componentsMap[seriesId] || null;

  return <div className="statsNguts__seriesMatchStats">{componentsToRender}</div>;
};

export default SeriesMatchStats;
