export const getH2HWinsAndLosses = (teamAName, teamBName, h2hMatches) => {
  const totalH2HMatches = h2hMatches.length;
  let teamATotalWins = 0;
  let teamBTotalWins = 0;
  for (let i = 0; i < totalH2HMatches; i += 1) {
    if (h2hMatches[i].Winner === teamAName) {
      teamATotalWins += 1;
    }
    if (h2hMatches[i].Winner === teamBName) {
      teamBTotalWins += 1;
    }
  }

  const teamAWinningPercentage = (parseFloat(teamATotalWins / totalH2HMatches).toFixed(2)) * 100;
  const teamBWinningPercentage = (parseFloat(teamBTotalWins / totalH2HMatches).toFixed(2)) * 100;

  const data = {
    totalH2HMatches,
    teamATotalWins,
    teamBTotalWins,
    teamAWinningPercentage,
    teamBWinningPercentage,
  };

  return data;
};

export const getTeamTopPerformers = (playerStats) => {
  const getTopPlayersByRunsScored = (playerRecords) => {
    let topPlayers = playerRecords.filter((item) => item.runsScored !== 0);
    topPlayers = topPlayers.sort((a, b) => {
      if (a.runsScored > b.runsScored) return -1;
      if (a.runsScored < b.runsScored) return 1;
      if (a.strikeRate > b.strikeRate) return -1;
      if (a.strikeRate < b.strikeRate) return 1;
      return 0;
    });

    if (topPlayers.length > 0) {
      return topPlayers[0];
    }
    return '';
  };

  const getTopPlayersByWicketsTaken = (playerRecords) => {
    let topPlayers = playerRecords.filter((item) => item.wicketsTaken !== 0);
    topPlayers = topPlayers.sort((a, b) => {
      if (a.wicketsTaken > b.wicketsTaken) return -1;
      if (a.wicketsTaken < b.wicketsTaken) return 1;
      if (a.economyRate > b.economyRate) return 1;
      if (a.economyRate < b.economyRate) return -1;
      return 0;
    });

    if (topPlayers.length > 0) {
      return topPlayers[0];
    }
    return '';
  };

  const getTopPlayersByStrikeRate = (playerRecords) => {
    let topPlayers = playerRecords.filter((item) => item.ballsFaced !== 0);
    topPlayers = topPlayers.filter((item) => item.ballsFaced >= 10);
    topPlayers = topPlayers.sort((a, b) => {
      if (a.strikeRate > b.strikeRate) return -1;
      if (a.strikeRate < b.strikeRate) return 1;
      if (a.runsScored > b.runsScored) return -1;
      if (a.runsScored < b.runsScored) return 1;
      return 0;
    });

    if (topPlayers.length > 0) {
      return topPlayers[0];
    }
    return '';
  };

  const getTopPlayersByEconomyRate = (playerRecords) => {
    let topPlayers = playerRecords.filter((item) => item.ballsBowled !== 0);
    topPlayers = playerRecords.filter((item) => item.ballsBowled >= 12);
    topPlayers = topPlayers.sort((a, b) => {
      if (a.economyRate > b.economyRate) return 1;
      if (a.economyRate < b.economyRate) return -1;
      if (a.wicketsTaken > b.wicketsTaken) return -1;
      if (a.wicketsTaken < b.wicketsTaken) return 1;
      return 0;
    });
    if (topPlayers.length > 0) {
      return topPlayers[0];
    }
    return '';
  };

  const teamAWicketkeepers = playerStats.teamA_WicketKeepers.filter((player) => player.status === 'Available');
  const teamABatters = playerStats.teamA_Batters.filter((player) => player.status === 'Available');
  const teamAAllRounders = playerStats.teamA_AllRounders.filter((player) => player.status === 'Available');
  const teamABowlers = playerStats.teamA_Bowlers.filter((player) => player.status === 'Available');
  const teamBWicketkeepers = playerStats.teamB_WicketKeepers.filter((player) => player.status === 'Available');
  const teamBBatters = playerStats.teamB_Batters.filter((player) => player.status === 'Available');
  const teamBAllRounders = playerStats.teamB_AllRounders.filter((player) => player.status === 'Available');
  const teamBBowlers = playerStats.teamB_Bowlers.filter((player) => player.status === 'Available');
  const teamAPlayerRecords = [];
  const teamBPlayerRecords = [];
  const teamAPlayers = teamAWicketkeepers.concat(teamABatters).concat(teamAAllRounders).concat(teamABowlers);
  const teamBPlayers = teamBWicketkeepers.concat(teamBBatters).concat(teamBAllRounders).concat(teamBBowlers);

  teamAPlayers.forEach((player) => {
    const { name, avatar, runsScored, wicketsTaken } = player;
    const lastTenRunsScored = runsScored.slice(0, 10);
    const lastWicketsTaken = wicketsTaken.slice(0, 10);
    let runsScoredTotal = 0;
    let ballsFacedTotal = 0;
    let wicketsTakenTotal = 0;
    let ballsBowledTotal = 0;
    let runsGivenTotal = 0;
    let strikeRate = 0;
    let economyRate = 0;
    if (lastTenRunsScored.length > 0) {
      runsScoredTotal = lastTenRunsScored.reduce((sum, current) => sum + current.Runs, 0);
      ballsFacedTotal = lastTenRunsScored.reduce((sum, current) => sum + current.Balls, 0);
    }
    if (lastWicketsTaken.length > 0) {
      wicketsTakenTotal = lastWicketsTaken.reduce((sum, current) => sum + current.Wickets, 0);
      ballsBowledTotal = lastWicketsTaken.reduce((sum, current) => sum + current.Balls, 0);
      runsGivenTotal = lastWicketsTaken.reduce((sum, current) => sum + current.Runs, 0);
    }

    if (ballsFacedTotal > 0) {
      strikeRate = Math.ceil((runsScoredTotal / ballsFacedTotal) * 100);
    }

    if (ballsBowledTotal > 0) {
      economyRate = parseFloat((runsGivenTotal / (ballsBowledTotal / 6)).toFixed(2));
    }

    const playerRecord = {
      name,
      runsScored: runsScoredTotal,
      ballsFaced: ballsFacedTotal,
      wicketsTaken: wicketsTakenTotal,
      runsGiven: runsGivenTotal,
      ballsBowled: ballsBowledTotal,
      strikeRate,
      economyRate,
      avatar,
    };

    teamAPlayerRecords.push(playerRecord);
  });

  teamBPlayers.forEach((player) => {
    const { name, avatar, runsScored, wicketsTaken } = player;
    const lastTenRunsScored = runsScored.slice(0, 10);
    const lastWicketsTaken = wicketsTaken.slice(0, 10);
    let runsScoredTotal = 0;
    let ballsFacedTotal = 0;
    let wicketsTakenTotal = 0;
    let ballsBowledTotal = 0;
    let runsGivenTotal = 0;
    let strikeRate = 0;
    let economyRate = 0;
    if (lastTenRunsScored.length > 0) {
      runsScoredTotal = lastTenRunsScored.reduce((sum, current) => sum + current.Runs, 0);
      ballsFacedTotal = lastTenRunsScored.reduce((sum, current) => sum + current.Balls, 0);
    }
    if (lastWicketsTaken.length > 0) {
      wicketsTakenTotal = lastWicketsTaken.reduce((sum, current) => sum + current.Wickets, 0);
      ballsBowledTotal = lastWicketsTaken.reduce((sum, current) => sum + current.Balls, 0);
      runsGivenTotal = lastWicketsTaken.reduce((sum, current) => sum + current.Runs, 0);
    }

    if (ballsFacedTotal > 0) {
      strikeRate = Math.ceil((runsScoredTotal / ballsFacedTotal) * 100);
    }

    if (ballsBowledTotal > 0) {
      economyRate = parseFloat((runsGivenTotal / (ballsBowledTotal / 6)).toFixed(2));
    }

    const playerRecord = {
      name,
      runsScored: runsScoredTotal,
      ballsFaced: ballsFacedTotal,
      wicketsTaken: wicketsTakenTotal,
      runsGiven: runsGivenTotal,
      ballsBowled: ballsBowledTotal,
      strikeRate,
      economyRate,
      avatar,
    };

    teamBPlayerRecords.push(playerRecord);
  });

  const teamATopPlayerRunsScored = getTopPlayersByRunsScored(teamAPlayerRecords);
  const teamAPlayerSortedByWickets = getTopPlayersByWicketsTaken(teamAPlayerRecords);
  const teamAPlayerSortedByStrikeRate = getTopPlayersByStrikeRate(teamAPlayerRecords);
  const teamAPlayerSortedByEconomyRate = getTopPlayersByEconomyRate(teamAPlayerRecords);
  const teamBPlayerSortedByRuns = getTopPlayersByRunsScored(teamBPlayerRecords);
  const teamBPlayerSortedByWickets = getTopPlayersByWicketsTaken(teamBPlayerRecords);
  const teamBPlayerSortedByStrikeRate = getTopPlayersByStrikeRate(teamBPlayerRecords);
  const teamBPlayerSortedByEconomyRate = getTopPlayersByEconomyRate(teamBPlayerRecords);

  const teamAStats = [];
  if (teamATopPlayerRunsScored) {
    const statObject = {
      title: 'Most Runs',
      playerName: teamATopPlayerRunsScored.name,
      playerImage: teamATopPlayerRunsScored.avatar,
      stat: {
        runsScored: teamATopPlayerRunsScored.runsScored,
        ballsFaced: teamATopPlayerRunsScored.ballsFaced,
        strikeRate: teamATopPlayerRunsScored.strikeRate,
        wicketsTaken: teamATopPlayerRunsScored.wicketsTaken,
        ballsBowled: teamATopPlayerRunsScored.ballsBowled,
        runsGiven: teamATopPlayerRunsScored.runsGiven,
        economyRate: teamATopPlayerRunsScored.economyRate,
      },
    };

    teamAStats.push(statObject);
  }
  if (teamAPlayerSortedByStrikeRate) {
    const mostRunObject = {
      title: 'Best Strike Rate',
      playerName: teamAPlayerSortedByStrikeRate.name,
      playerImage: teamAPlayerSortedByStrikeRate.avatar,
      stat: {
        runsScored: teamAPlayerSortedByStrikeRate.runsScored,
        ballsFaced: teamAPlayerSortedByStrikeRate.ballsFaced,
        strikeRate: teamAPlayerSortedByStrikeRate.strikeRate,
        wicketsTaken: teamAPlayerSortedByStrikeRate.wicketsTaken,
        ballsBowled: teamAPlayerSortedByStrikeRate.ballsBowled,
        runsGiven: teamAPlayerSortedByStrikeRate.runsGiven,
        economyRate: teamAPlayerSortedByStrikeRate.economyRate,
      },
    };

    teamAStats.push(mostRunObject);
  }
  if (teamAPlayerSortedByWickets) {
    const mostRunObject = {
      title: 'Most Wickets',
      playerName: teamAPlayerSortedByWickets.name,
      playerImage: teamAPlayerSortedByWickets.avatar,
      stat: {
        runsScored: teamAPlayerSortedByWickets.runsScored,
        ballsFaced: teamAPlayerSortedByWickets.ballsFaced,
        strikeRate: teamAPlayerSortedByWickets.strikeRate,
        wicketsTaken: teamAPlayerSortedByWickets.wicketsTaken,
        ballsBowled: teamAPlayerSortedByWickets.ballsBowled,
        runsGiven: teamAPlayerSortedByWickets.runsGiven,
        economyRate: teamAPlayerSortedByWickets.economyRate,
      },
    };

    teamAStats.push(mostRunObject);
  }
  if (teamAPlayerSortedByEconomyRate) {
    const mostRunObject = {
      title: 'Best Economy Rate',
      playerName: teamAPlayerSortedByEconomyRate.name,
      playerImage: teamAPlayerSortedByEconomyRate.avatar,
      stat: {
        runsScored: teamAPlayerSortedByEconomyRate.runsScored,
        ballsFaced: teamAPlayerSortedByEconomyRate.ballsFaced,
        strikeRate: teamAPlayerSortedByEconomyRate.strikeRate,
        wicketsTaken: teamAPlayerSortedByEconomyRate.wicketsTaken,
        ballsBowled: teamAPlayerSortedByEconomyRate.ballsBowled,
        runsGiven: teamAPlayerSortedByEconomyRate.runsGiven,
        economyRate: teamAPlayerSortedByEconomyRate.economyRate,
      },
    };

    teamAStats.push(mostRunObject);
  }

  const teamBStats = [];
  if (teamBPlayerSortedByRuns) {
    const statObject = {
      title: 'Most Runs',
      playerName: teamBPlayerSortedByRuns.name,
      playerImage: teamBPlayerSortedByRuns.avatar,
      stat: {
        runsScored: teamBPlayerSortedByRuns.runsScored,
        ballsFaced: teamBPlayerSortedByRuns.ballsFaced,
        strikeRate: teamBPlayerSortedByRuns.strikeRate,
        wicketsTaken: teamBPlayerSortedByRuns.wicketsTaken,
        ballsBowled: teamBPlayerSortedByRuns.ballsBowled,
        runsGiven: teamBPlayerSortedByRuns.runsGiven,
        economyRate: teamBPlayerSortedByRuns.economyRate,
      },
    };

    teamBStats.push(statObject);
  }
  if (teamBPlayerSortedByStrikeRate) {
    const mostRunObject = {
      title: 'Best Strike Rate',
      playerName: teamBPlayerSortedByStrikeRate.name,
      playerImage: teamBPlayerSortedByStrikeRate.avatar,
      stat: {
        runsScored: teamBPlayerSortedByStrikeRate.runsScored,
        ballsFaced: teamBPlayerSortedByStrikeRate.ballsFaced,
        strikeRate: teamBPlayerSortedByStrikeRate.strikeRate,
        wicketsTaken: teamBPlayerSortedByStrikeRate.wicketsTaken,
        ballsBowled: teamBPlayerSortedByStrikeRate.ballsBowled,
        runsGiven: teamBPlayerSortedByStrikeRate.runsGiven,
        economyRate: teamBPlayerSortedByStrikeRate.economyRate,
      },
    };

    teamBStats.push(mostRunObject);
  }
  if (teamBPlayerSortedByWickets) {
    const mostRunObject = {
      title: 'Most Wickets',
      playerName: teamBPlayerSortedByWickets.name,
      playerImage: teamBPlayerSortedByWickets.avatar,
      stat: {
        runsScored: teamBPlayerSortedByWickets.runsScored,
        ballsFaced: teamBPlayerSortedByWickets.ballsFaced,
        strikeRate: teamBPlayerSortedByWickets.strikeRate,
        wicketsTaken: teamBPlayerSortedByWickets.wicketsTaken,
        ballsBowled: teamBPlayerSortedByWickets.ballsBowled,
        runsGiven: teamBPlayerSortedByWickets.runsGiven,
        economyRate: teamBPlayerSortedByWickets.economyRate,
      },
    };

    teamBStats.push(mostRunObject);
  }
  if (teamBPlayerSortedByEconomyRate) {
    const mostRunObject = {
      title: 'Best Economy Rate',
      playerName: teamBPlayerSortedByEconomyRate.name,
      playerImage: teamBPlayerSortedByEconomyRate.avatar,
      stat: {
        runsScored: teamBPlayerSortedByEconomyRate.runsScored,
        ballsFaced: teamBPlayerSortedByEconomyRate.ballsFaced,
        strikeRate: teamBPlayerSortedByEconomyRate.strikeRate,
        wicketsTaken: teamBPlayerSortedByEconomyRate.wicketsTaken,
        ballsBowled: teamBPlayerSortedByEconomyRate.ballsBowled,
        runsGiven: teamBPlayerSortedByEconomyRate.runsGiven,
        economyRate: teamBPlayerSortedByEconomyRate.economyRate,
      },
    };

    teamBStats.push(mostRunObject);
  }

  const data = {
    teamAStats,
    teamBStats,
  };

  return data;
};

export const getPlayerMatchups = (playerStats) => {
  const teamAWicketkeepers = playerStats.teamA_WicketKeepers.filter((player) => player.status === 'Available');
  const teamABatters = playerStats.teamA_Batters.filter((player) => player.status === 'Available');
  const teamAAllRounders = playerStats.teamA_AllRounders.filter((player) => player.status === 'Available');
  const teamABowlers = playerStats.teamA_Bowlers.filter((player) => player.status === 'Available');
  const teamBWicketkeepers = playerStats.teamB_WicketKeepers.filter((player) => player.status === 'Available');
  const teamBBatters = playerStats.teamB_Batters.filter((player) => player.status === 'Available');
  const teamBAllRounders = playerStats.teamB_AllRounders.filter((player) => player.status === 'Available');
  const teamBBowlers = playerStats.teamB_Bowlers.filter((player) => player.status === 'Available');
  const playerMatchups = [];
  const teamAPlayers = teamAWicketkeepers.concat(teamABatters).concat(teamAAllRounders).concat(teamABowlers);
  const teamBPlayers = teamBWicketkeepers.concat(teamBBatters).concat(teamBAllRounders).concat(teamBBowlers);

  teamAPlayers.forEach((player) => {
    const { name, avatar, runsScoredAndWicketsGivenAgainstBowlersOfOpposition, wicketsTakenAndRunsGivenAgainstBattersOfOpposition } = player;
    if (runsScoredAndWicketsGivenAgainstBowlersOfOpposition.length > 0) {
      const validMatchups = runsScoredAndWicketsGivenAgainstBowlersOfOpposition.filter((item) => item.Runs >= 20 || item.Wickets >= 2);
      if (validMatchups.length > 0) {
        validMatchups.forEach((element) => {
          const teamBPlayer = teamBPlayers.find((item) => item.name === element.Bowler);
          if (teamBPlayer) {
            let highlight = false;
            if (element.Wickets >= 2 || element.Runs >= 40) {
              highlight = true;
            }
            const matchup = {
              batter: {
                name,
                image: avatar,
              },
              bowler: {
                name: teamBPlayer.name,
                image: teamBPlayer.avatar,
              },
              stats: {
                balls: element.Balls,
                runs: element.Runs,
                wickets: element.Wickets,
              },
              highlight,
            };

            playerMatchups.push(matchup);
          }
        });
      }
    }

    if (wicketsTakenAndRunsGivenAgainstBattersOfOpposition.length > 0) {
      const validMatchups = wicketsTakenAndRunsGivenAgainstBattersOfOpposition.filter((item) => item.Runs >= 20 || item.Wickets >= 2);
      if (validMatchups.length > 0) {
        validMatchups.forEach((element) => {
          const teamBPlayer = teamBPlayers.find((item) => item.name === element.Batter);
          if (teamBPlayer) {
            let highlight = false;
            if (element.Wickets >= 2 || element.Runs >= 40) {
              highlight = true;
            }
            const matchup = {
              batter: {
                name: teamBPlayer.name,
                image: teamBPlayer.avatar,
              },
              bowler: {
                name,
                image: avatar,
              },
              stats: {
                balls: element.Balls,
                runs: element.Runs,
                wickets: element.Wickets,
              },
              highlight,
            };
            playerMatchups.push(matchup);
          }
        });
      }
    }
  });

  const data = {
    playerMatchups,
  };

  return data;
};
