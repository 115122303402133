/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState } from 'react';
import './player.css'; // Import the CSS file
import PlayerOverview from '../../common/playerOverview/PlayerOverview';
import PlayerCharts from '../playerCharts/PlayerCharts';

const Player = ({ player, avatar, ground, opposition, key, type }) => {
  const [isPlayerCardVisible, setIsPlayerCardVisible] = useState(false);
  const [isPlayerOverviewVisible, setIsPlayerOverviewVisible] = useState(false);
  const [isPlayerTrendsVisible, setIsPlayerTrendsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('wplHistory');

  const handlePlayerTitleClick = () => {
    setIsPlayerCardVisible(!isPlayerCardVisible);
  };

  const handlePlayerOverviewClick = () => {
    setIsPlayerOverviewVisible(!isPlayerOverviewVisible);
  };

  const handlePlayerTrendsClick = () => {
    setIsPlayerTrendsVisible(!isPlayerTrendsVisible);
  };

  const wicketKeeperTabs = [];
  const batterTabs = [];
  const allrounderTabs = [];
  const bowlerTabs = [];

  if (player.wplHistory && player.wplHistory.length > 0) {
    wicketKeeperTabs.push({ tab: 'wplHistory', label: 'WPL History' });
    batterTabs.push({ tab: 'wplHistory', label: 'WPL History' });
    allrounderTabs.push({ tab: 'wplHistory', label: 'WPL History' });
    bowlerTabs.push({ tab: 'wplHistory', label: 'WPL History' });
  } else {
    wicketKeeperTabs.push({ tab: 'wplHistory', label: 'WPL History - No Stats Since 2023' });
    batterTabs.push({ tab: 'wplHistory', label: 'WPL History - No Stats Since 2023' });
    allrounderTabs.push({ tab: 'wplHistory', label: 'WPL History - No Stats Since 2023' });
    bowlerTabs.push({ tab: 'wplHistory', label: 'WPL History - No Stats Since 2023' });
  }
  if (player.runsScored && player.runsScored.length > 0) {
    wicketKeeperTabs.push({ tab: 'runsScored', label: 'Runs in WPL' });
    batterTabs.push({ tab: 'runsScored', label: 'Runs in WPL' });
    allrounderTabs.push({ tab: 'runsScored', label: 'Runs in WPL' });
    bowlerTabs.push({ tab: 'runsScored', label: 'Runs in WPL' });
  }
  if (player.domesticT20RunsScored && player.domesticT20RunsScored.length > 0) {
    wicketKeeperTabs.push({ tab: 'domesticT20RunsScored', label: 'Runs in Domestic India T20' });
    batterTabs.push({ tab: 'domesticT20RunsScored', label: 'Runs in Domestic India T20' });
    allrounderTabs.push({ tab: 'domesticT20RunsScored', label: 'Runs in Domestic India T20' });
    bowlerTabs.push({ tab: 'domesticT20RunsScored', label: 'Runs in Domestic India T20' });
  }
  if (player.internationalT20RunsScored && player.internationalT20RunsScored.length > 0) {
    wicketKeeperTabs.push({ tab: 'internationalT20RunsScored', label: 'Runs in International T20' });
    batterTabs.push({ tab: 'internationalT20RunsScored', label: 'Runs in International T20' });
    allrounderTabs.push({ tab: 'internationalT20RunsScored', label: 'Runs in International T20' });
    bowlerTabs.push({ tab: 'internationalT20RunsScored', label: 'Runs in International T20' });
  }
  if (player.internationalT20LeagueRunsScored && player.internationalT20LeagueRunsScored.length > 0) {
    wicketKeeperTabs.push({ tab: 'internationalT20LeagueRunsScored', label: 'Runs in International T20 Leagues' });
    batterTabs.push({ tab: 'internationalT20LeagueRunsScored', label: 'Runs in International T20 Leagues' });
    allrounderTabs.push({ tab: 'internationalT20LeagueRunsScored', label: 'Runs in International T20 Leagues' });
    bowlerTabs.push({ tab: 'internationalT20LeagueRunsScored', label: 'Runs in International T20 Leagues' });
  }
  if (player.runsScoredInGround && player.runsScoredInGround.length > 0) {
    wicketKeeperTabs.push({ tab: 'runsScoredInGround', label: `Runs in ${ground}` });
    batterTabs.push({ tab: 'runsScoredInGround', label: `Runs in ${ground}` });
    allrounderTabs.push({ tab: 'runsScoredInGround', label: `Runs in ${ground}` });
    bowlerTabs.push({ tab: 'runsScoredInGround', label: `Runs in ${ground}` });
  }
  if (player.runsScoredAgainstOpposition && player.runsScoredAgainstOpposition.length > 0) {
    wicketKeeperTabs.push({ tab: 'runsScoredAgainstOpposition', label: `Runs Vs ${opposition}` });
    batterTabs.push({ tab: 'runsScoredAgainstOpposition', label: `Runs Vs ${opposition}` });
    allrounderTabs.push({ tab: 'runsScoredAgainstOpposition', label: `Runs Vs ${opposition}` });
    bowlerTabs.push({ tab: 'runsScoredAgainstOpposition', label: `Runs Vs ${opposition}` });
  }
  if (player.runsScoredAgainstOppositionInGround && player.runsScoredAgainstOppositionInGround.length > 0) {
    wicketKeeperTabs.push({ tab: 'runsScoredAgainstOppositionInGround', label: `Runs Vs ${opposition} in ${ground}` });
    batterTabs.push({ tab: 'runsScoredAgainstOppositionInGround', label: `Runs Vs ${opposition} in ${ground}` });
    allrounderTabs.push({ tab: 'runsScoredAgainstOppositionInGround', label: `Runs Vs ${opposition} in ${ground}` });
    bowlerTabs.push({ tab: 'runsScoredAgainstOppositionInGround', label: `Runs Vs ${opposition} in ${ground}` });
  }
  if (player.runsScoredAndWicketsGivenAgainstBowlersOfOpposition && player.runsScoredAndWicketsGivenAgainstBowlersOfOpposition.length > 0) {
    wicketKeeperTabs.push({ tab: 'runsScoredAndWicketsGivenAgainstBowlersOfOpposition', label: `Batting Performance Vs ${opposition} Bowlers` });
    batterTabs.push({ tab: 'runsScoredAndWicketsGivenAgainstBowlersOfOpposition', label: `Batting Performance Vs ${opposition} Bowlers` });
    allrounderTabs.push({ tab: 'runsScoredAndWicketsGivenAgainstBowlersOfOpposition', label: `Batting Performance Vs ${opposition} Bowlers` });
    bowlerTabs.push({ tab: 'runsScoredAndWicketsGivenAgainstBowlersOfOpposition', label: `Batting Performance Vs ${opposition} Bowlers` });
  }
  if (player.wicketsTaken && player.wicketsTaken.length > 0) {
    wicketKeeperTabs.push({ tab: 'wicketsTaken', label: 'Wickets in WPL' });
    batterTabs.push({ tab: 'wicketsTaken', label: 'Wickets in WPL' });
    allrounderTabs.push({ tab: 'wicketsTaken', label: 'Wickets in WPL' });
    bowlerTabs.push({ tab: 'wicketsTaken', label: 'Wickets in WPL' });
  }
  if (player.domesticT20WicketsTaken && player.domesticT20WicketsTaken.length > 0) {
    wicketKeeperTabs.push({ tab: 'domesticT20WicketsTaken', label: 'Wickets in Domestic T20' });
    batterTabs.push({ tab: 'domesticT20WicketsTaken', label: 'Wickets in Domestic T20' });
    allrounderTabs.push({ tab: 'domesticT20WicketsTaken', label: 'Wickets in Domestic T20' });
    bowlerTabs.push({ tab: 'domesticT20WicketsTaken', label: 'Wickets in Domestic T20' });
  }
  if (player.internationalT20WicketsTaken && player.internationalT20WicketsTaken.length > 0) {
    wicketKeeperTabs.push({ tab: 'internationalT20WicketsTaken', label: 'Wickets in International T20' });
    batterTabs.push({ tab: 'internationalT20WicketsTaken', label: 'Wickets in International T20' });
    allrounderTabs.push({ tab: 'internationalT20WicketsTaken', label: 'Wickets in International T20' });
    bowlerTabs.push({ tab: 'internationalT20WicketsTaken', label: 'Wickets in International T20' });
  }
  if (player.internationalT20LeagueWicketsTaken && player.internationalT20LeagueWicketsTaken.length > 0) {
    wicketKeeperTabs.push({ tab: 'internationalT20LeagueWicketsTaken', label: 'Wickets in International T20 Leagues' });
    batterTabs.push({ tab: 'internationalT20LeagueWicketsTaken', label: 'Wickets in International T20 Leagues' });
    allrounderTabs.push({ tab: 'internationalT20LeagueWicketsTaken', label: 'Wickets in International T20 Leagues' });
    bowlerTabs.push({ tab: 'internationalT20LeagueWicketsTaken', label: 'Wickets in International T20 Leagues' });
  }
  if (player.wicketsTakenInGround && player.wicketsTakenInGround.length > 0) {
    wicketKeeperTabs.push({ tab: 'wicketsTakenInGround', label: `Wickets in ${ground}` });
    batterTabs.push({ tab: 'wicketsTakenInGround', label: `Wickets in ${ground}` });
    allrounderTabs.push({ tab: 'wicketsTakenInGround', label: `Wickets in ${ground}` });
    bowlerTabs.push({ tab: 'wicketsTakenInGround', label: `Wickets in ${ground}` });
  }
  if (player.wicketsTakenAgainstOpposition && player.wicketsTakenAgainstOpposition.length > 0) {
    wicketKeeperTabs.push({ tab: 'wicketsTakenAgainstOpposition', label: `Wickets Vs ${opposition}` });
    batterTabs.push({ tab: 'wicketsTakenAgainstOpposition', label: `Wickets Vs ${opposition}` });
    allrounderTabs.push({ tab: 'wicketsTakenAgainstOpposition', label: `Wickets Vs ${opposition}` });
    bowlerTabs.push({ tab: 'wicketsTakenAgainstOpposition', label: `Wickets Vs ${opposition}` });
  }
  if (player.wicketsTakenAgainstOppositionInGround && player.wicketsTakenAgainstOppositionInGround.length > 0) {
    wicketKeeperTabs.push({ tab: 'wicketsTakenAgainstOppositionInGround', label: `Wickets Vs ${opposition} in ${ground}` });
    batterTabs.push({ tab: 'wicketsTakenAgainstOppositionInGround', label: `Wickets Vs ${opposition} in ${ground}` });
    allrounderTabs.push({ tab: 'wicketsTakenAgainstOppositionInGround', label: `Wickets Vs ${opposition} in ${ground}` });
    bowlerTabs.push({ tab: 'wicketsTakenAgainstOppositionInGround', label: `Wickets Vs ${opposition} in ${ground}` });
  }
  if (player.wicketsTakenAndRunsGivenAgainstBattersOfOpposition && player.wicketsTakenAndRunsGivenAgainstBattersOfOpposition.length > 0) {
    wicketKeeperTabs.push({ tab: 'wicketsTakenAndRunsGivenAgainstBattersOfOpposition', label: `Bowling Performance Vs ${opposition} Batters` });
    batterTabs.push({ tab: 'wicketsTakenAndRunsGivenAgainstBattersOfOpposition', label: `Bowling Performance Vs ${opposition} Batters` });
    allrounderTabs.push({ tab: 'wicketsTakenAndRunsGivenAgainstBattersOfOpposition', label: `Bowling Performance Vs ${opposition} Batters` });
    bowlerTabs.push({ tab: 'wicketsTakenAndRunsGivenAgainstBattersOfOpposition', label: `Bowling Performance Vs ${opposition} Batters` });
  }

  const tabsConfig = {
    wicketKeeper: wicketKeeperTabs,
    batter: batterTabs,
    allrounder: allrounderTabs,
    bowler: bowlerTabs,
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const getHeadingRow = () => {
    switch (type) {
      case 'wicketKeeper':
      case 'batter':
      case 'allrounder':
      case 'bowler':
        switch (activeTab) {
          case 'wplHistory':
            return ['Season', 'Team', 'Price', 'Matches', 'Innings', 'Runs Scored', 'Wickets Taken'];
          case 'runsScored':
            return ['Runs', 'Balls', 'Team', 'Against', 'Series', 'Match', 'Ground', 'Date'];
          case 'domesticT20RunsScored':
            return ['Runs', 'Balls', 'Team', 'Against', 'Series', 'Match', 'Ground', 'Date'];
          case 'internationalT20RunsScored':
            return ['Runs', 'Balls', 'Team', 'Against', 'Series', 'Match', 'Ground', 'Date'];
          case 'internationalT20LeagueRunsScored':
            return ['Runs', 'Balls', 'Team', 'Against', 'Series', 'Match', 'Ground', 'Date'];
          case 'runsScoredInGround':
            return ['Runs', 'Balls', 'Team', 'Against', 'Series', 'Match', 'Date'];
          case 'runsScoredAgainstOpposition':
            return ['Runs', 'Balls', 'Team', 'Series', 'Match', 'Ground', 'Date'];
          case 'runsScoredAgainstOppositionInGround':
            return ['Runs', 'Balls', 'Team', 'Series', 'Match', 'Date'];
          case 'runsScoredAndWicketsGivenAgainstBowlersOfOpposition':
            return ['Bowler', 'Balls', 'Runs', 'Wickets'];
          case 'wicketsTaken':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Against', 'Series', 'Match', 'Ground', 'Date'];
          case 'domesticT20WicketsTaken':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Against', 'Series', 'Match', 'Ground', 'Date'];
          case 'internationalT20WicketsTaken':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Against', 'Series', 'Match', 'Ground', 'Date'];
          case 'internationalT20LeagueWicketsTaken':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Against', 'Series', 'Match', 'Ground', 'Date'];
          case 'wicketsTakenInGround':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Against', 'Series', 'Match', 'Date'];
          case 'wicketsTakenAgainstOpposition':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Series', 'Match', 'Ground', 'Date'];
          case 'wicketsTakenAgainstOppositionInGround':
            return ['Wickets', 'Balls', 'Runs', 'Team', 'Series', 'Match', 'Date'];
          case 'wicketsTakenAndRunsGivenAgainstBattersOfOpposition':
            return ['Batter', 'Balls', 'Runs', 'Wickets'];
          default:
            return [];
        }
      default:
        return [];
    }
  };

  const playerStatus = player.status;
  const playerStatusClass = 'status-green';
  /*
  if (matchInfo.isStartingElevenFinal === 'Yes') {
    if (playerStatus === 'Available') {
      if (isPlayerInStartingEleven) {
        playerStatus = 'In Playing XI';
      } else if (isPlayerInImpactPlayerList) {
        playerStatus = 'Impact Player';
      } else {
        playerStatus = 'Not In Playing Squad';
      }
    }
  }

  let playerStatusClass = '';
  if (player.status === 'Available') {
    playerStatusClass = 'status-green';
    if (matchInfo.isStartingElevenFinal === 'Yes') {
      playerStatusClass = 'status-green';
      if (isPlayerInStartingEleven) {
        playerStatusClass = 'status-green';
      } else if (isPlayerInImpactPlayerList) {
        playerStatusClass = 'status-yellow';
      } else {
        playerStatusClass = 'status-red';
      }
    }
  } else {
    playerStatusClass = 'status-red';
  }
  */

  return (
    <div className="statsNguts__player">
      <div className="statsNguts__player_title" onClick={handlePlayerTitleClick}>
        <h3>{player.name} - {player.country}<br />
          <h5 className={playerStatusClass}>
            {playerStatus}
          </h5>
        </h3>
      </div>
      {isPlayerCardVisible && (
        <div className="statsNguts__player_card" key={key}>
          <div className="statsNguts__player_card_avatarContainer">
            <img className="statsNguts__player_card_avatarContainer_avatar" src={require(`../../../../assets/image/players/${avatar}`)} alt="playerAvatar" />
            <div className="statsNguts__player_card_price">
              <h4>{player.price}</h4>
            </div>
            <div className="statsNguts__player_card_batting_style">
              <h4>Batting - {player.battingStyle}</h4>
            </div>
            <div className="statsNguts__player_card_bowling_style">
              <h4>Bowling - {player.bowlingStyle}</h4>
            </div>
            <div className="statsNguts__player_card_player_overview" onClick={handlePlayerOverviewClick}>
              <h4>Player Overview<br />
                <h5 className="clickToKnowMore">
                  Click to Know More!
                </h5>
              </h4>
            </div>
            <div className="statsNguts__player_card_player_trends" onClick={handlePlayerTrendsClick}>
              <h4>Player Trends<br />
                <h5 className="clickToKnowMore">
                  Click to Know More!
                </h5>
              </h4>
            </div>
          </div>
          <div className="statsNguts__player_card_content">
            {isPlayerOverviewVisible && (
              <div className="statsNguts__player_card_playerOverview">
                <PlayerOverview player={player} />
              </div>
            )}
            {isPlayerTrendsVisible && (
            <div className="statsNguts__player_card_playerCharts">
              <PlayerCharts player={player} type={type} />
            </div>
            )}
            <div className="statsNguts__player_card_stats_container">
              <h1>Stats</h1>
              <div className="statsNguts__player_card_stats">
                <div className="statsNguts__player_card_stats_tabs">
                  {tabsConfig[type].map((tabInfo) => (
                    <button
                      type="button"
                      key={tabInfo.tab}
                      className={`statsNguts__player_card_stats_tabs_button ${activeTab === tabInfo.tab ? 'active' : ''}`}
                      onClick={() => handleTabChange(tabInfo.tab)}
                    >
                      {tabInfo.label}
                    </button>
                  ))}
                </div>
                <div className="statsNguts__player_card_stats_details">
                  <div className="statsNguts__player_card_stats_details_table">
                    <table>
                      <tbody>
                        <tr>
                          {getHeadingRow().map((heading, index) => (
                            <th key={index}>{heading}</th>
                          ))}
                        </tr>
                        {player[activeTab].map((entry, index) => (
                          <tr key={index}>
                            {getHeadingRow().map((column, columnIndex) => (
                              <td key={columnIndex} className={`statsNguts__player_card_stats_details_table_entry ${column === 'Overview' ? 'overview-column' : ''}`}>
                                {entry[column]}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Player;
